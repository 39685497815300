import { CircularProgress, MenuItem } from "@material-ui/core"
import { MenuList } from "@material-ui/core"

import { useFetchGuestCommunicationShortcodes } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import { IShortcode } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { spacing } from "src/ui/spacing"

export function SelectShortcode({
  onSelect,
}: {
  onSelect: (shortcode: IShortcode) => void
}) {
  const fetchShortcodes = useFetchGuestCommunicationShortcodes({})
  const shortcodes = fetchShortcodes.data

  return (
    <DropdownButton
      displayValue="Add shortcode"
      variant="minimal"
      placement="bottom-end"
    >
      {({ closeMenu }) => (
        <MenuList>
          {/* FIXME: PD-1877 handle errors */}
          {fetchShortcodes.isLoading && (
            <MenuItem disabled>
              <CircularProgress size={15} style={{ marginRight: spacing.M }} />
              Loading...
            </MenuItem>
          )}
          {shortcodes?.map((shortcode) => (
            <MenuItem
              onClick={() => {
                onSelect(shortcode)
                closeMenu()
              }}
              key={shortcode.shortcode}
            >
              {shortcode.shortcode_name}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </DropdownButton>
  )
}
